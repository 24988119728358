import React from 'react';

import { graphql } from 'gatsby';

import CommonLandingPageTemplate from './LandingPageTemplate';
import AnoraProLandingPageTemplate from './anoraPro';
import { siteId } from '../../sites';
import { siteIds } from '../../constants';

function LandingPageTemplate({ ...props }) {
  if (siteId === siteIds.AnoraPro) {
    return <AnoraProLandingPageTemplate {...props} />;
  }
  return <CommonLandingPageTemplate {...props} />;
}

export const pageQuery = graphql`
  query LandingPageTemplate($pageId: String!) {
    contentfulLandingPage(id: { eq: $pageId }) {
      id
      slug
      fullPath
      contentful_id
      menuTitle
      shortDescription
      languageCode
      customStyleFontFamilyLinks
      internal {
        type
      }
      searchPage {
        ...SearchPageFragment
      }
      languageVersions {
        ...LanguageVersionPageFragment
      }
      seoFields {
        ...SeoDataFragment
      }
      primaryChannel {
        ...PrimaryChannelFragment
      }
      contentRows {
        ...ContentRowFragment
      }
      heroImage {
        ...HeroImageFragment
      }
      topNavigation {
        ...TopNavigationFragment
      }
      mainTheme {
        ...themeFragment
      }
      alternativeTheme {
        ...themeFragment
      }
    }
  }
`;

export default LandingPageTemplate;
